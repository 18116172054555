import { Route } from "@angular/router";
import { AuthGuard } from "@grainger/angular/guards/auth.guard";

export const appRoutes: Route[] = [
    {
        path: "auth",
        loadChildren: () => import("./auth/auth.routes").then((r) => r.routes),
        canActivate: [AuthGuard.canActivateUnauthenticated]
    },
    {
        path: "users",
        loadChildren: () => import("./users/users.routes").then((r) => r.routes),
        canActivate: [AuthGuard.canActivateAuthenticated, AuthGuard.canActivateAsAdmin]
    },
    {
        path: "**",
        redirectTo: "users"
    }
];
