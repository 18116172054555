import { provideHttpClient, withInterceptors } from "@angular/common/http";
import { ApplicationConfig, provideAppInitializer, provideZoneChangeDetection } from "@angular/core";
import { provideRouter } from "@angular/router";
import { sharedEnvironment } from "@grainger/angular/environment/shared-environment";
import { authInterceptor } from "@grainger/angular/interceptors/auth.interceptor";
import { tokenInterceptor } from "@grainger/angular/interceptors/token.interceptor";
import { InitUtil } from "@grainger/angular/util/init.util";
import { appRoutes } from "./app.routes";

export const appConfig: ApplicationConfig = {
    providers: [
        { provide: InitUtil.APP_ENVIRONMENT, useFactory: () => sharedEnvironment },
        provideAppInitializer(InitUtil.init),
        provideHttpClient(withInterceptors([authInterceptor, tokenInterceptor])),
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideRouter(appRoutes)
    ]
};
