<app-header #header></app-header>
<div class="container-fluid">
    <div class="row flex-nowrap">
        @if (authService.user$ | async) {
            <div
                class="col-12 col-md-3 col-xl-2 px-sm-2 px-0 bg-dark collapse"
                [ngbCollapse]="header.menuCollapsed && !isXl"
                [horizontal]="true"
            >
                <div class="d-flex flex-column flex-shrink-0 py-3 text-white bg-dark c-nav">
                    <ul class="nav nav-pills flex-column mb-auto">
                        <li class="nav-item">
                            <a class="nav-link" [routerLink]="['/users']" routerLinkActive="active">
                                <lib-icon name="people-fill" class="me-2"></lib-icon>
                                Users
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        }

        <div class="col overflow-auto">
            <ng-content></ng-content>
        </div>
    </div>
</div>
