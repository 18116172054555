import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { Router, RouterModule } from "@angular/router";
import { AuthService } from "@grainger/angular/services/auth.service";
import { NgbDropdownModule, NgbNavModule } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],
    standalone: true,
    imports: [CommonModule, RouterModule, NgbDropdownModule, NgbNavModule],
    providers: []
})
export class HeaderComponent {
    public menuCollapsed: boolean = true;

    constructor(
        public authService: AuthService,
        private router: Router
    ) {}

    public async signOut() {
        this.authService.logout();
        await this.router.navigate(["/auth", "login"]);
    }
}
