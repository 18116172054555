import { Component } from "@angular/core";
import { RouterModule } from "@angular/router";
import { NavComponent } from "./core/components/nav/nav.component";

@Component({
    imports: [RouterModule, NavComponent],
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrl: "./app.component.scss"
})
export class AppComponent {
    title = "grainger-facilities-admin";
}
