import { CommonModule } from "@angular/common";
import { Component, inject } from "@angular/core";
import { RouterModule } from "@angular/router";
import { IconComponent } from "@grainger/angular/components/icon/icon.component";
import { AuthService } from "@grainger/angular/services/auth.service";
import { NgbCollapseModule } from "@ng-bootstrap/ng-bootstrap";
import { HeaderComponent } from "../header/header.component";

@Component({
    selector: "app-nav",
    standalone: true,
    imports: [CommonModule, HeaderComponent, NgbCollapseModule, RouterModule, IconComponent],
    templateUrl: "./nav.component.html",
    styleUrl: "./nav.component.scss"
})
export class NavComponent {
    public readonly authService = inject(AuthService);

    public get isXl() {
        return window.innerWidth >= 1200;
    }
}
