import { NgIf } from "@angular/common";
import { Component, Input } from "@angular/core";

@Component({
    selector: "lib-icon",
    templateUrl: "./icon.component.html",
    styleUrls: ["./icon.component.scss"],
    standalone: true,
    imports: [NgIf]
})
export class IconComponent {
    /**
     * Path to the svg icon
     *
     * Note: Make sure to prep svg icons with the following:
     *
     * - Set fill attribute to `currentColor` where applicable
     * - Remove width and height attributes from the root svg element
     * - Add "id" attribute with value "icon" to the root svg element
     */
    @Input() path!: string;

    /**
     * Name of the bootstrap font icon
     * https://icons.getbootstrap.com/
     */
    @Input() name!: string;
}
