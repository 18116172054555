<nav class="navbar navbar-expand-xl px-2 bg-dark" data-bs-theme="dark">
    <div class="container-fluid py-2">
        <button class="navbar-toggler me-4" type="button" (click)="menuCollapsed = !menuCollapsed">&#9776;</button>
        <a class="navbar-brand d-flex align-items-center" routerLink="/">
            <img class="navbar-logo" src="assets/images/logo.svg" alt="Grainger Logo" />
            <span class="vl"></span>
            <span class="h2 mb-0 font-fix"> Admin</span>
        </a>
        @if (authService.user$ | async; as user) {
            <div class="d-flex flex-fill justify-content-end">
                <div class="text-center ps-4" id="navMenu">
                    <div class="d-inline-block" ngbDropdown placement="bottom-end">
                        <button
                            id="profile-button"
                            class="btn btn-outline-light d-flex align-items-center"
                            ngbDropdownToggle
                            type="button"
                        >
                            <i class="bi bi-person-circle me-2"></i>
                            <div class="navbar-profile__name">{{ user.email }}</div>
                        </button>
                        <div id="profile-dropdown" class="bg-light" ngbDropdownMenu data-bs-theme="light">
                            <button ngbDropdownItem (click)="signOut()">Sign Out</button>
                        </div>
                    </div>
                </div>
            </div>
        }
    </div>
</nav>
